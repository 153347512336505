// Home Page
.home {
  // Intro section
  &__intro {
    background-color: $bg1;
    min-height: calc(100vh - 404px);
    &-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      padding: 56px 0;
    }
    &-left {
      h2 {
        font-weight: 600;
        font-size: 40px;
        line-height: 56px;
        span {
          color: #0879f9;
        }
      }
      p {
        margin-right: 90px;
      }
    }
    &-right {
      flex-shrink: 0;
      .intro1 {
        width: 420px;
        height: auto;
      }
    }
  }
  &__video {
    padding: 120px 0;
    display: flex;
    align-items: center;
    gap: 120px;
    background-color: #fff;

    .video {
      width: 520px;
      height: 320px;
      flex-shrink: 0;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      &__frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .play {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #00000030;

        svg {
          height: 64px;
          width: 64px;
          cursor: pointer;
        }
      }
    }
  }
}

// ---------------------------------------
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  img {
    width: 48px;
  }
  span {
    font-size: 24px;
    line-height: 32px;
    color: #12141d;
    font-weight: 700;
  }
}

.title {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.5px;
  color: #000000;
}

.info {
  padding: 24px 0 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #12141d;
  opacity: 0.8;
  span {
    color: #0879f9;
    font-weight: 600;
  }
}

.arrowBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background: #0879f9;
  border-radius: 4px;
  border: none;
  width: 300px;
  cursor: pointer;
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    font-family: "Poppins", sans-serif;
  }
  svg {
    transform: translateX(-10px);
    transition: 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: translateX(0);
    }
  }
  &:disabled {
    background-color: #8c8c8c !important;
    &:hover {
      background-color: #8c8c8c !important;
    }
  }
}

.kirgan__menu {
  right: 0;
  top: 16px;
  z-index: 2;
  border-radius: 4px;
  width: 310px;
  padding: 24px;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  .profile__tab {
    padding: 14px !important;
    cursor: pointer;
    margin-top: 4px;
    gap: 14px;
    background-color: white;
    border-radius: 4px;
    border: none;
    justify-content: flex-start !important;
    width: 100%;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
    }
  }

  .profileBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
    background: #e6f7ff;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #1890ff;
    }
  }

  .logout {
    margin-top: 12px;
    width: 100%;
    cursor: pointer;
    background: #ffeeee;
    border-radius: 4px;
    border: none;
    padding: 12px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ff5858;
  }
}

.nodata-anim {
  h2 {
    font-size: 20px;
    font-weight: 400;
    margin-top: -64px;
    text-align: center;
  }
}

// Modals
.login__modal {
  width: 440px !important;
  position: relative;
  .ant-form-item {
    &:last-child {
      margin-bottom: 0;
      .ant-btn {
        margin-top: 16px;
        width: 100%;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        height: 48px;
        border-radius: 4px;
      }
    }
  }
  & > button {
    width: 100%;
    padding: 12px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    background: #4e46b4;
    border-radius: 4px;
    border: none;
  }
  .alert {
    margin-bottom: 24px;
    .ant-alert-message {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
    }
  }
  .backButton {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24%;
    background: #ffffff;
    border: none;
    position: absolute;
    top: 14px;
    left: -16px;
    cursor: pointer;
    transform: rotate(45deg);
    svg {
      transform: rotate(-45deg);
    }
    &:hover {
      box-shadow: 0px 6px 6px -6px #00000029, 0px 0px 1px #00000066;
      background: #f5f5f5;
    }
  }
  .reSend {
    width: 100%;
    padding: 12px 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #8c8c8c;
    background: #d9d9d9;
    border-radius: 4px;
    border: none;
    margin-top: 24px;
    cursor: pointer;
  }
  .reSend-active {
    color: white;
    background-color: #1890ff;
  }
  .modal1 {
    & > button {
      width: 100%;
      padding: 12px 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      background: #4e46b4;
      border-radius: 4px;
      border: none;
      cursor: pointer;
    }
  }
  .modal2 {
    .ant-form > p {
      text-align: right;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      cursor: pointer;
    }
  }
}

// Media for mobile Large screens
@media (max-width: 1300px) {
  // logo
  .logo {
    img {
      width: 56px;
      height: 56px;
    }
    span {
      font-size: 20px;
    }
  }
  .title {
    font-size: 28px;
    line-height: 42px;
  }

  // Home
  .home {
    &__video {
      gap: 24px;
      padding: 64px 0;
      flex-direction: column;
      width: 80%;

      .video {
        width: 100%;
        height: 400px;
        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
    &__faq {
      padding: 64px 0;
    }
  }

  // Footer
  .footer {
    &__nav {
      flex-wrap: wrap;
      gap: 42px;
      padding-bottom: 16px;
      & > div {
        flex-grow: 1;
      }
    }
    &__info .info {
      padding-bottom: 0;
    }
  }
}

// Media for mobile Tablets
@media (max-width: 860px) {
  // Header
  .header {
    &__nav {
      & > a {
        display: none;
      }
    }
    &__profile {
      .kirgan {
        & > span {
          display: none;
        }
      }
    }
  }

  // Home
  .home {
    &__intro {
      &-right {
        display: none;
      }
      &-left {
        h2 {
          font-size: 32px;
          line-height: 48px;
        }
      }
      &-stat {
        padding: 32px;
        justify-content: center;
        & > div {
          gap: 0;
          width: auto;
          h2 {
            font-size: 28px;
            line-height: normal;
          }
        }
      }
    }
    &__social {
      .flex {
        flex-direction: column;
        & > div {
          order: 1;
          gap: 24px;
        }
      }
    }
    &__video {
      width: 90%;
    }
  }
}

// Media for mobile Phones
@media (max-width: 500px) {
  // logo
  .logo {
    img {
      width: 36px;
      height: 36px;
    }
    span {
      font-size: 16px;
    }
  }
  .title {
    font-size: 22px;
    line-height: 32px;
  }
  .login__modal {
    width: 84% !important;
  }
  .info {
    font-size: 16px;
  }
  .nodata-anim {
    h2 {
      font-size: 18px;
    }
  }

  // Home
  .home {
    &__intro {
      &-left {
        h2 {
          font-size: 26px;
          line-height: 36px;
        }
        p {
          margin-right: 0;
          font-size: 16px;
        }
      }
    }

    &__video {
      gap: 24px;
      padding: 64px 0;
      flex-direction: column-reverse;

      .video {
        width: 100%;
        height: 220px;
        iframe {
          width: 100%;
        }
      }
    }

    &__faq {
      .ant-collapse-header-text {
        font-size: 16px;
      }
    }
  }

  // Footer
  .footer {
    padding-top: 32px;
    &__nav {
      flex-wrap: wrap;
      gap: 42px;
      padding-bottom: 16px;
      & > div {
        flex-grow: 1;
      }
    }
    &__info {
      .info {
        padding: 8px 0 0;
      }
      .logo {
        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .home__intro-left h2 {
    font-size: 22px;
    line-height: 30px;
  }
}
