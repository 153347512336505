.layout {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    background-color: #fff !important;
    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
    }
  }
  &-logo {
    display: flex;
    align-items: center;
    gap: 12px;
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: $bold;
    }
  }
  &-sider {
    height: 100vh;
    a {
      height: 64px;
      display: flex;
      align-items: center;
      gap: 12px;
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #ffffff;
      padding: 12px 16px;
    }
  }
  &-badge {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .info .ant-badge-count {
      background-color: $blue1;
      color: $primary;
    }
    .warning .ant-badge-count {
      background-color: $warning;
    }
    .success .ant-badge-count {
      background-color: $success;
    }
    .error .ant-badge-count {
      background-color: $error;
    }
    .ant-badge .ant-badge-count {
      box-shadow: none;
    }
  }
  &-button {
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-img {
      width: 40px;
      object-fit: cover;
      cursor: pointer;
      filter: invert(1);
    }
  }
  &-filter {
    &-sort {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      span {
        display: block;
        &:first-child svg {
          margin-bottom: -5px;
        }
      }
      svg {
        fill: $text25;
      }
      .active svg {
        fill: $primary;
      }
    }
  }
  &-content {
    padding: 24px 24px 0;
    background-color: #f5f5f5;
    height: calc(100vh - 64px);
    overflow: auto;
    &-pages {
      min-height: calc(100vh - 156px);
      padding: 24px;
      background-color: #ffffff;
      border-radius: 5px;
    }
    &-default {
      min-height: calc(100vh - 156px);
    }
    &-header {
      display: flex;
      padding-bottom: 20px;
      gap: 20px;
      p {
        flex-grow: 1;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.85);

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      .excel {
        border: 1px solid $excel;
        background-color: $excel;
        &:hover,
        &:focus {
          background-color: $excel !important;
        }
      }
      .right {
        display: flex;
        gap: 20px;
        margin-left: auto;
      }
      .ant-upload-list {
        display: none;
      }
    }
    &-table {
      &-buttons {
        display: flex;
        gap: 10px;
        span {
          padding: 5px;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          &:hover {
            background-color: rgb(228, 212, 212);
          }

          &:first-child {
            svg {
              color: $warning;
            }
          }
          &:nth-child(2) {
            svg {
              color: $error;
            }
          }
          &:nth-child(3) {
            svg {
              color: $link;
            }
          }
        }
      }
    }
    &-quota {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .ant-alert {
        padding: 0 10px;
        &-message {
          text-align: center;
        }
      }
    }
    &-total {
      font-size: 18px;
      line-height: 24px;
      margin-top: -24px;
      span {
        font-weight: 600;
      }
    }
    .ant-table-pagination {
      display: none;
    }
    &-users {
      .ant-table-pagination {
        display: flex;
      }
    }
  }
  &-footer {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    span {
      font-weight: 500;
      margin-right: 8px;
    }
  }
  &-icons {
    &-delete {
      cursor: pointer;
      svg {
        fill: $red5;
      }
    }
  }
  &-detail {
    background-color: #fff;
    padding: 24px;
    margin-top: 12px;
    border-radius: 5px;
    &-header {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      border-bottom: 1px solid $border;
      padding-bottom: 12px;
      margin-bottom: 24px;
    }
    .ant-table-pagination {
      display: flex;
    }
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
    .dynamic-delete-button {
      position: relative;
      top: 4px;
      margin: 0 15px;
      color: #999;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
      font-size: 30px;
      cursor: pointer;
    }
  }
  &-form {
    &-level {
      display: flex;
      column-gap: 24px;
      flex-wrap: wrap;
      & > div {
        width: calc(100% / 3 - 18px);
        &:first-child {
          width: 100%;
        }
      }
    }
    &-speciality {
      border: 1px solid $border;
      padding: 11px;
      border-radius: 6px;
    }
  }
}

.line-clamp3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
@media only screen and (max-width: 1100px) {
  .layout-form-level {
    & > div {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 700px) {
  .layout {
    &-navbar {
      flex-wrap: wrap;
      row-gap: 20px;
      padding: 24px;
      &-menu {
        width: 100%;
        order: 3;
        display: flex;
        justify-content: center;
      }
      &-right {
        gap: 0;
        svg {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 450px) {
  .layout {
    &-navbar {
      padding: 16px;
    }
  }
}
