.login {
  &-admin {
    overflow-x: hidden;
    position: relative;
    background-color: #f2f2f7;
    &-top {
      height: 50vh;
      background: linear-gradient(90deg, #41319cd6, #4b38b3d6),
        url("https://themesbrand.com/velzon/html/material/assets/images/auth-one-bg.jpg");
      background-position: center;
    }
    &-bottom {
      width: 100%;
      height: 100%;
      min-height: 45vh;
      padding-bottom: 60px;
      display: flex;
      position: relative;
      &-left {
        width: calc(50% + 200px);
        height: 200px;
        background-color: #f2f2f7;
        transform: rotateZ(10deg);
        margin-left: -200px;
        margin-top: -90px;
      }
      &-right {
        width: calc(50% + 200px);
        height: 200px;
        background-color: #f2f2f7;
        transform: rotateZ(-10deg);
        margin-right: -200px;
        margin-top: -90px;
        margin-left: auto;
      }
    }
    &-content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      &-header {
        text-align: center;
        margin-bottom: 40px;
        a {
          font-size: 24px;
          padding-top: 100px;
          font-weight: 600;
          color: white;
          margin: 0 auto;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
        h2 {
          font-size: 16px;
          line-height: 23px;
          color: rgba(255, 255, 255, 0.5);
          letter-spacing: 1.1px;
        }
      }
      &-form {
        width: 400px;
        box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
        border-radius: 6px;
        padding: 1.5rem;
        background-color: #fff;
      }
    }
    &-footer {
      height: 45px;
      text-align: center;
      font-size: 13px;
      line-height: 20px;
      color: #878a99;
    }
  }

  &-main {
    &-title {
      width: calc(100% - 40px);
      font-size: 30px;
      line-height: 40px;
      color: $black8;
      font-weight: 600;
      margin-bottom: 16px;
      color: $black8;
    }
    &-button {
      border-radius: 4px !important;
      &-oneid {
        width: 100%;
        background-color: #4327ba;
        border-color: #4327ba;
        border-radius: 4px !important;
        &:hover,
        &:focus {
          background-color: #4327ba !important;
          border-color: #4327ba !important;
          opacity: 0.9;
        }
      }
      &-back {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 1001;

        background: #ffffff;
        border: 1px solid #edeef0;
        box-shadow: 0px 8px 32px rgba(0, 0, 0, 0.08);
        transform: matrix(-1, 0, 0, 1, 0, 0);

        position: absolute;
        top: 24px;
        left: -24px;
      }
    }
    &-or {
      margin: 24px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $black8;
      span {
        width: 80px;
        height: 1px;
        border: 1px solid #dfe2ea;
      }
    }
  }

  &-modal {
    .ant-modal-content {
      padding: 24px 32px;
      border-radius: 10px;
    }
    .ant-tabs-nav-list {
      width: 100%;
      .ant-tabs-tab {
        width: 50%;
        text-align: center;
        .ant-tabs-tab-btn {
          width: 100%;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .login {
    & > .ant-tabs {
      width: 84%;
    }
    &-admin-content-form {
      width: calc(100% - 32px);
    }
    &-main {
      &-title {
        font-size: 24px;
        line-height: 32px;
      }
    }
    &-modal {
      .ant-modal-content {
        padding: 20px 16px;
      }
    }
  }
}
