.upload {
  &-input {
    position: relative;
    &-box {
      width: 100%;
      height: 48px;
      border: 1px solid #e7e9e8;
      border-radius: 4px;
      padding: 8px 12px;
      cursor: pointer;
      &:hover,
      &:focus {
        border-color: var(--main);
      }
      div {
        display: flex;
        align-items: center;
        gap: 12px;
      }
      &-empty p {
        color: #7d89a1;
      }
    }
    &-link {
      color: #004fff;
      position: absolute;
      top: 12px;
      right: 12px;
    }
    &-small {
      height: 40px;
      padding: 5px 11px;
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }
}
