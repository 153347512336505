// .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
//   background-color: #1890ff60;
// }

// .ant-radio-button-wrapper:first-child {
//   border-radius: 8px 0 0 8px;
// }
// .ant-radio-button-wrapper:last-child {
//   border-radius: 0 8px 8px 0;
// }

// .ant-input {
//   border-color: #bac7d5;
//   &::placeholder {
//     color: #697d95;
//   }
// }
// .ant-select-selection-placeholder {
//   color: #697d95;
// }
// .ant-tabs-content {
//   position: initial;
// }

// // All ant inputs
// .ant-input,
// .ant-input-number,
// .ant-select-selector {
//   padding: 8px 16px !important;
//   height: 48px !important;
//   background: #fafbfc;
//   border: 1px solid #dfe2ea !important;
//   border-radius: 4px !important;
//   font-weight: 400;
//   font-size: 16px;
//   line-height: 24px;
//   color: #000000;
// }
// .ant-input-number {
//   width: 100%;
//   .ant-input-number-input {
//     padding: 0;
//     height: inherit;
//   }
// }
// .ant-input-password {
//   padding: 0 16px;
// }
// .ant-input-affix-wrapper {
//   border-radius: 4px;
//   background: #fafbfc;
//   input {
//     height: 44px;
//   }
// }
// .ant-modal-title {
//   font-weight: 600;
//   font-size: 20px;
//   line-height: 28px;
//   color: #000000;
// }
// .ant-form-item-label label {
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 24px;
//   color: #000000;
// }
// .ant-modal-content {
//   border-radius: 10px;
//   .ant-modal-header {
//     border-radius: 10px 10px 0 0;
//   }
// }
// .ant-picker {
//   width: 100%;
//   height: 48px;
//   border-radius: 4px;
//   input {
//     font-size: 16px !important;
//   }
// }
// .info-modal {
//   width: 600px !important;
//   .ant-modal-body {
//     img {
//       width: 100%;
//     }
//   }
// }
// .ant-input-password {
//   input {
//     transition: none;
//     &::placeholder {
//       font-size: 16px !important;
//     }
//   }
//   input[type="password"] {
//     font-family: Verdana, sans-serif;
//     font-size: 18px;
//     &::placeholder {
//       font-family: "Montserrat", sans-serif;
//     }
//   }
// }

// //Drawer classes
// .test-drawer {
//   .ant-drawer-header {
//     display: none;
//   }
// }

.no-close-button {
  .ant-modal-close {
    display: none;
  }
}

.no-close-button2 .ant-modal-footer button:first-child {
  display: none;
}
