.notfound {
  padding: 80px 0 60px;
  &-header {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    &-unavailable {
      text-align: center;
      margin-bottom: -30px;
    }
    &-404 {
      margin-bottom: 40px;
    }
  }
  &-button {
    margin: 0px auto;
    &-denied {
      margin-right: 30px;
    }
  }
}

@media screen and (max-width: 540px) {
  .notfound {
    padding: 48px 0;
    &-button-denied {
      margin-right: 0;
    }
  }
}
