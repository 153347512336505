:root {
  --base-grid: 8px;
  --colour-body-background: #1890ff;
  --colour-background: #fff;
  --colour-background-folded: #f5f5f5;
  --colour-background-stripes: rgba(255, 255, 255, 0.5);
  --colour-text: #1a1a1a;
}

.subjects {
  padding: 40px 0 100px;
  &-header {
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 48px;
      line-height: 64px;
      letter-spacing: -1px;
      margin-bottom: 24px;
    }
    p {
      width: 70%;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #12141d;
      opacity: 0.7;
      margin: 0 auto 72px;
    }
  }
  &-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 24px;
  }
  &-modal {
    img {
      width: 302px;
      object-fit: cover;
    }
    h3 {
      color: #586875;
      font-size: 1.5rem;
    }
    p {
      color: $text;
      font-size: 16px;
      span {
        font-weight: 600;
      }
    }
  }
  &-results {
    .success {
      color: $success;
    }
    .error {
      color: $error;
    }
  }
}

.no-data-p {
  color: $text;
}

.articles__article {
  cursor: pointer;
  display: block;
  position: relative;
  animation-name: animateIn;
  animation-duration: 0.35s;
  animation-delay: calc(var(--animation-order) * 100ms);
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  &:first-child .articles__link {
    background-color: #a0abb6;
  }
  &:nth-child(2) .articles__link {
    background-color: #b7c1cb;
  }
  &:nth-child(3) .articles__link {
    background-color: #414e5e;
  }
  &:nth-child(4) .articles__link {
    background-color: #566e6d;
  }
  &:nth-child(5) .articles__link {
    background-color: #a8a39a;
  }
  &:nth-child(6) .articles__link {
    background-color: #ccd5e0;
  }
  &:nth-child(7) .articles__link {
    background-color: #a1cbba;
  }
  &:nth-child(8) .articles__link {
    background-color: #8c7f7d;
  }
}

.articles__article:before {
  content: "";
  position: absolute;
  top: calc(var(--base-grid) * -2);
  left: calc(var(--base-grid) * -2);
  border: 2px dashed var(--colour-background);
  background-image: repeating-linear-gradient(
    -24deg,
    transparent,
    transparent 4px,
    var(--colour-background-stripes) 0,
    var(--colour-background-stripes) 5px
  );
  z-index: -1;
}

.articles__article,
.articles__article:before {
  width: calc(25% - 18px);
  min-width: 250px;
  height: calc(var(--base-grid) * 20);
}

.articles__link {
  background-color: var(--colour-body-background);
  border: 2px solid var(--colour-background);
  border: none;
  display: block;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.articles__link:after {
  content: "";
  position: absolute;
  top: 50%;
  right: calc(var(--base-grid) * 3);
  width: calc(var(--base-grid) * 2);
  height: calc(var(--base-grid) * 2);
  margin-top: calc(var(--base-grid) * -1);
  clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0 100%, 25% 50%, 0 0);
  -webkit-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0 100%, 25% 50%, 0 0);
  background-color: var(--colour-background);
  opacity: 0;
  transition: opacity 0.5s ease-in, transform 0.3s ease-in-out 0ms;
}

.articles__content {
  background-color: var(--colour-background);
  color: var(--colour-text);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: calc(var(--base-grid) * 2);
  display: flex;
  flex-direction: column;
  border: 2px solid var(--colour-background);
  background: linear-gradient(90deg, #4e709742, #4966868f);
  background-size: cover;
  border: none;
}

.articles__content--lhs {
  clip-path: polygon(0 0, 51% 0, 51% 100%, 0 100%);
  -webkit-clip-path: polygon(0 0, 51% 0, 51% 100%, 0 100%);
}

.articles__content--rhs {
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  -webkit-clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  transition: transform 0.5s ease-in-out, background-color 0.4s ease-in-out;
}

.articles__title {
  font-size: calc(var(--base-grid) * 4);
  line-height: 1.125;
  font-weight: 700;
  letter-spacing: -0.02em;
  color: #fff;
}

.articles__footer {
  margin-top: auto;
  font-size: calc(var(--base-grid) * 2);
  line-height: calc(var(--base-grid) * 2);
  display: flex;
  justify-content: space-between;
}

.articles__link:hover .articles__content--rhs {
  background-color: var(--colour-background-folded);
  transform: rotateY(-50deg);
}

.articles__link:hover:after {
  opacity: 1;
  transform: translateX(calc(var(--base-grid) * 1.5));
  transition: opacity 0.5s ease-in, transform 0.3s ease-in-out 0.25s;
}

//Responsive section
@media (max-width: 1200px) {
  .subjects-content {
    gap: 18px;
  }
  .articles__article,
  .articles__article:before {
    width: calc(33% - 12px);
  }
}

@media (max-width: 890px) {
  .subjects {
    &-content {
      gap: 16px;
    }
    &-header {
      margin-bottom: -20px;
      p {
        width: 100%;
      }
    }
  }
  .articles__article,
  .articles__article:before {
    width: calc(50% - 8px);
  }
}

@media (max-width: 595px) {
  .subjects {
    padding: 30px 0 60px;
    &-content {
      gap: 10px;
    }
    &-header {
      margin-bottom: -40px;
      h3 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 12px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .articles__article,
  .articles__article:before {
    width: calc(50% - 5px);
    min-width: 150px;
    height: calc(var(--base-grid) * 10);
  }
  .articles__title {
    font-size: calc(var(--base-grid) * 2.7);
    letter-spacing: -0.01em;
  }
  .articles__content {
    padding: calc(var(--base-grid) * 1);
  }
}

@media (max-width: 360px) {
  .subjects-content {
    gap: 10px;
  }
  .articles__article,
  .articles__article:before {
    width: 100%;
    min-width: 150px;
    height: calc(var(--base-grid) * 15);
  }
}
