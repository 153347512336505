@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
:after,
:before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

html,
body {
  font-family: "Poppins", sans-serif !important;
  scroll-behavior: smooth;
}

// Variables colors
$text: #999999;
$text25: rgba(0, 0, 0, 0.25);
$text45: rgba(0, 0, 0, 0.45);
$text-hover: rgba(255, 255, 255, 0.9);
$border: #d9d9d9;
$bg1: #f8f8f8;
$bg2: #dadada;
$bold: rgba(0, 0, 0, 0.88);
$black8: rgba(0, 0, 0, 0.8);
$link: #1890ff;
$primary: #1677ff;
$warning: #faad14;
$success: #52c41a;
$error: #ff4d4f;
$volcano6: #fa541c;
$red1: #fff1f0;
$red2: #ffccc7;
$red3: #ffa39e;
$red5: #ff4d4f;
$red6: #f5222d;
$purple1: #f9f0ff;
$purple3: #d3adf7;
$purple6: #722ed1;
$purple8: #391085;
$blue1: #e6f4ff;
$blue2: #bae0ff;
$blue3: #91caff;
$blue5: #4096ff;
$blue7: #0958d9;
$blue10: #001d66;
$green1: #f6ffed;
$green2: #d9f7be;
$green3: #b7eb8f;
$green5: #73d13d;
$green7: #389e0d;
$excel: #20c45f;
$cyan1: #e6fffb;
$cyan3: #87e8de;
$cyan5: #36cfc9;
$cyan6: #13c2c2;
$magenta1: #fff0f6;
$magenta3: #ffadd2;
$magenta7: #eb2f96;
$min-height: calc(100vh - (283px + 83px));
$shadow: 0px 1px 2px rgba(0, 0, 0, 0.03), 0px 1px 6px -1px rgba(0, 0, 0, 0.02),
  0px 2px 4px rgba(0, 0, 0, 0.02);

:root {
  --main: #1890ff;
}

a {
  color: inherit;
  text-decoration: none;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.container {
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
}

.flex {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:active {
    transform: scale(0.98);
  }
}

.main-content {
  min-height: calc(100vh - 404px);
}
.full-width {
  width: 100%;
}
.halw-width {
  width: 50%;
}

@import "./home/";
@import "./header";
@import "./footer/";
@import "./subject/";
@import "./test/";
@import "./filter/";
@import "./rating/";
@import "./layout/";
@import "./login/";
@import "./profile/";
@import "./modal/";
@import "./common/upload.scss";
@import "./notfound/";
@import "./button/layered-button.scss";
@import "./package/ant.scss";
