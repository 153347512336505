.layered-button {
  a {
    position: relative;
    width: 100%;
    padding-top: 25.92%;
    display: block;
    background-size: 100% 100%;
    font-size: 24px;
    font-weight: 500;
    text-decoration: none;
    color: #333;
  }

  a.background-button {
    background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/323909/yellow-shadow.png);

    &:after {
      content: attr(title);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/323909/yellow-top.png);
      transition: all 0.3s;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover:after {
      -webkit-transform: translate(2.5%, 10%);
      transform: translate(2.5%, 10%);
      text-shadow: 2px 2px 1px white;
      color: black;
    }
  }

  .button-wrapper {
    width: 355px;
    display: inline-block;
    transform: scale(0.6);
    margin-left: -60px;

    &:nth-child(2) a {
      -webkit-filter: hue-rotate(180deg);
      filter: hue-rotate(180deg);
    }
  }
}
