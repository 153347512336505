// Header
.header {
  .container {
    padding: 32px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__nav {
    display: flex;
    align-items: center;
    gap: 32px;

    a {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #12141d;
    }

    .kirish {
      padding: 12px 32px;
      background: #0879f9;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      border: none;
      cursor: pointer;
      font-family: "Poppins", sans-serif;
    }

    .kirgan {
      display: flex;
      align-items: center;
      gap: 12px;
      cursor: pointer;
      position: relative;

      &__img {
        background: #0879f9;
        border: 1px solid #ffffff;
        border-radius: 4px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #262626;
      }
    }
  }

  &_hemisId {
    flex-grow: 1;
    font-size: 16px;
    text-align: end;
    padding-right: 32px;
    span {
      font-weight: 600;
    }
  }
}

@media (max-width: 650px) {
  .header {
    .container {
      flex-wrap: wrap;
    }
    &_hemisId {
      width: 100%;
      order: 3;
      padding-top: 20px;
    }
  }
}
