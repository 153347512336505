.test {
  display: flex;
  &-container {
    width: 856px;
    margin: 0 auto;
    padding-bottom: 100px;
    &-footer {
      width: 250px;
      margin-left: auto;
      display: none !important;
    }
    h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 40px;
      letter-spacing: -0.5px;
    }
  }
  &-button {
    display: none;
    margin: 20px 0 0 auto;
    float: right;
  }
  &-box {
    padding-top: 80px;
    & > .flex {
      justify-content: flex-start;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }

      h2 {
        flex-shrink: 0;
        opacity: 0.6;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        color: black;
      }

      div {
        font-weight: 600;
      }
    }
    &-variants {
      a {
        position: relative;
        margin-top: 16px;
        background: #fff;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        padding: 22px 18px;
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        &:hover {
          color: black;
        }
        h3 {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          margin-left: 2px;
        }
        p {
          font-size: 18px;
          line-height: 24px;
        }
        img {
          width: 100% !important;
        }
        div {
          width: 100%;
        }
        .correct-answer {
          position: absolute;
          top: 18px;
          right: 12px;
          width: 32px !important;
          height: 32px !important;
          // transform: translateY(-50%);
        }
        .checkbox {
          width: 24px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          & > div {
            width: 17.6px;
            height: 17.6px;
            border: 2px solid rgb(217, 217, 217);
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .active {
            border-color: #1c84ff;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width: 10px;
              height: 10px;
              fill: $blue10;
              font-weight: bold;
            }
            // span {
            //   width: 8px;
            //   height: 8px;
            //   background-color: rgb(28, 132, 255);
            //   border-radius: 50%;
            // }
          }
        }
      }
    }
    &-active {
      background: #e6f7ff !important;
      border: 1px solid #1890ff !important;
      h3,
      p {
        color: #1890ff;
      }
    }
    &-correct {
      width: calc(100% - 65px) !important;
    }
  }
  &-table {
    position: sticky;
    top: 0px;
    right: 0px;

    width: 398px;
    height: 100vh;
    background: #ffffff;
    padding: 24px;
    overflow-y: auto;
    /* width */
    &::-webkit-scrollbar {
      width: 0;
    }

    &-close {
      text-align: end;
      display: none;
      svg {
        cursor: pointer;
      }
    }
    &-header {
      h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.5px;
        margin-bottom: 12px;
        color: $bold;
      }
      &-info {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f0f0f0;
        p {
          margin-bottom: 26px;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          span {
            color: var(--main);
          }
        }
        &-right {
          width: 163px;
          margin-bottom: 26px;
          span {
            font-weight: 600;
            font-size: 16px;
            // line-height: 24px;
            margin-bottom: -4px;
          }
          .time {
            display: flex;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
    &-numbers {
      padding-top: 26px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 12px;
      a {
        width: 40px;
        height: 40px;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }
    }
    &-number {
      background: #e6f7ff;
      border: 1px solid var(--main) !important;
      color: var(--main);
    }
    &-footer {
      background-color: #d9d9d9;
      height: 48px;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      cursor: pointer;
      margin-top: 150px;
      transition: 0.3s;
      &:hover {
        background-color: $success;
        color: white;
      }
    }
  }
  &-drawer {
    .ant-drawer-header {
      display: none;
    }
  }
}

//Responsive section
@media (max-width: 1450px) {
  .test {
    &-container {
      &-footer {
        display: flex !important;
        margin-top: 100px;
      }
    }
    &-button {
      display: initial;
    }
    &-table {
      display: none;
      &-close {
        display: block;
      }
    }
  }
}
@media (max-width: 980px) {
  .test {
    &-container {
      width: 100%;
    }
  }
}
@media (max-width: 540px) {
  .test {
    &-box {
      h2 {
        font-size: 18px;
        line-height: 24px;
      }
      &-img {
        padding: 12px 0;
        margin-top: 12px;
      }
      &-variants {
        a {
          margin-top: 12px;
          padding: 14px 6px;
          gap: 6px;
          h3 {
            font-size: 16px;
            line-height: 20px;
            margin-left: 1px;
          }
          p {
            font-size: 16px;
            line-height: 20px;
          }
          .correct-answer {
            top: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .test-container-footer {
    width: 100%;
  }
}
