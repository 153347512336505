.rating {
  padding: 40px 0 100px;
  &-filter {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    margin-top: 24px;
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
  }
}
