.modal {
  &-subc {
    &-remove {
      &-input {
        width: calc(100% - 40px);
      }
      &-button {
        position: relative;
        top: 4px;
        margin: 0 8px;
        color: #999;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          color: #777;
        }
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  &-success {
    &-content {
      padding-top: 30px;
      p {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
        span {
          color: $blue5;
          cursor: pointer;
        }
      }
      .title {
        margin-top: 50px;
      }
      // .text {
      //   font-size: 20px;
      //   line-height: 28px;
      // }
    }
    .ant-modal {
      &-content {
        background-color: $blue10;
      }
      &-footer {
        border-top: 1px solid #002c8c;
        padding-top: 24px;
        .ant-btn:first-child {
          display: none;
        }
      }
      &-close-x {
        display: none;
      }
    }
  }
  &-delete {
    h3 {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      color: var(--bold);
      line-height: 30px;
    }
    &-buttons {
      display: flex;
      justify-content: center;
      gap: 15px;
      margin-top: 30px;
      div {
        width: 50%;
        &:first-child {
          text-align: end;
          button {
            background-color: #f3f6f9;
            box-shadow: 0 3px 3px rgba(56, 65, 74, 0.1);
          }
        }
      }
    }
  }
}
