.profile {
  padding: 0 0 84px;
  .container {
    display: flex;
    align-items: flex-start;
    gap: 36px;
  }
  &__navbar {
    padding: 32px 0;
    gap: 12px !important;
    .flex:first-child {
      gap: 8px;
      transition: 0.3s ease-in-out;
      svg path {
        transition: 0.3s ease-in-out;
      }
      &:hover {
        color: #1890ff;
        svg path {
          fill: #1890ff;
        }
      }
    }
    .flex:last-child {
      color: #1890ff;
    }
    .flex {
      cursor: pointer;
      span {
        font-weight: 400;
      }
    }
  }
  &__sidebar {
    width: 300px;
    padding: 16px;
    background-color: white;
    border-radius: 8px;
    flex-shrink: 0;
  }
  &__menu {
    & > h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      margin-bottom: 12px;
    }
  }
  &__tab {
    cursor: pointer;
    gap: 14px;
    padding: 14px;
    background-color: white;
    border-radius: 4px;
    border: none;
    justify-content: flex-start !important;
    width: 100%;

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #212121;
      font-family: "Poppins", sans-serif;
    }
  }
  &__tab-active {
    background: #e6f7ff;
    svg path {
      fill: #1890ff;
    }

    span {
      color: #1890ff !important;
      font-weight: 600;
    }
  }
  &__body {
    flex-grow: 1;
    width: 100%;
  }
  &__info {
    width: 100%;
    h2 {
      margin-bottom: 16px;
    }
    p {
      display: flex;
      justify-content: space-between;
      padding: 6px 8px;
      margin-bottom: 6px;
      font-size: 16px;
      line-height: 24px;
      color: #595959;
      font-weight: 600;
      &:nth-child(odd) {
        background-color: #f5f5f5;
      }
    }
    span {
      width: calc(100% - 180px);
      line-height: 20px;
      text-align: end;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      font-weight: 600;
    }
  }
  &__statistcs {
    border-radius: 8px;
    padding: 16px 12px 0 0;
    background-color: #ffffff;
  }
  &__test {
    .test-container {
      width: 100%;
      .test-box {
        &:first-child {
          padding-top: 0;
        }
        &-incorrect {
          background: #ffe5e5 !important;
          border: 1px solid #ff4d4f !important;
          h3,
          p {
            fill: #ff4d4f;
          }

          svg path,
          svg circle {
            fill: #ff4d4f;
          }
        }
      }
    }
  }
  .testinfo {
    &__card {
      gap: 16px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;

      padding: 8px 24px;
      border-radius: 8px;
      border: 1px solid $border;

      margin-bottom: 12px;

      h2 {
        flex-grow: 1;
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      p {
        font-size: 14px;
        color: #8c8c8c;
        font-weight: 500;
        span {
          color: #000000;
        }
        .correct {
          color: $success;
        }
        .incorrect {
          color: $error;
        }
      }

      &__right {
        display: flex;
        align-items: flex-end;
      }
    }
    .ant-tabs-tab-btn {
      color: #212121;
      font-weight: 600;
    }
  }
}

.ant-notification-notice-message {
  font-weight: 600;
}

@media (max-width: 1050px) {
  .profile {
    .container {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 600px) {
  .profile {
    padding: 0 0 36px;
    .container {
      & > div {
        flex-grow: 1;
        width: 100%;
      }
    }
    &__sidebar {
      padding: 16px;
    }
    &__menu {
      & > h4 {
        font-size: 20px;
        line-height: 28px;
      }
    }
    &__info {
      p {
        flex-direction: column;
        span {
          width: 100%;
          text-align: start;
        }
      }
    }
  }
}
